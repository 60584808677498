import { createContext, useContext, useState } from 'react';
import axios from 'utils/axiosInstance';
import ErrorFallback from 'page/ErrorFallback';

// Create the Error Context
const ErrorContext = createContext();

// Custom hook to use the Error Context
export const useError = () => useContext(ErrorContext);

// ErrorProvider Component
export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const handleError = async (error) => {
    setError(error);
    // Log error to an error reporting service
    console.error('Logged error:', error);
    await axios.post('/email/error', {
      email: 'team@hoppadigital.com',
      error: error.stack,
    });
  };

  const resetError = () => setError(null);

  return (
    <ErrorContext.Provider value={{ error, handleError, resetError }}>
      {error ? (
        <ErrorFallback />
      ) : (
        children
      )}
    </ErrorContext.Provider>
  );
};
