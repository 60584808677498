import logoLight from 'assets/images/logo/logo-light.png';

const Header = ({ logo, color = 'default-color' }) => {
  let Logo;
  if (logo === 'light') {
    Logo = <img src={logoLight} alt='Hoppa Digital' />;
  } else {
    Logo = <img src={logoLight} alt='Hoppa Digital' />;
  }

  return (
    <header
      className={`header-area formobile-menu header--transparent ${color}`}
    >
      <div className='header-wrapper' id='header-wrapper'>
        <div className='header-left'>
          <div className='logo'>
            <a href='/'>{Logo}</a>
          </div>
        </div>
        <div className='header-right'>
          <a href='/' className='rn-btn'>
            <span>Home</span>
          </a>
        </div>
      </div>
    </header>
  );
};
export default Header;
