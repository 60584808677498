import { Helmet } from 'react-helmet';

const PageHelmet = ({ pageTitle }) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta
        name='description'
        content='Hoppa Digital is a digital agency that specializes in creating custom websites, mobile applications, and digital marketing strategies.'
      />
    </Helmet>
  );
};

export default PageHelmet;
