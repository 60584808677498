import { Component, Fragment } from 'react';
import Scrollspy from 'react-scrollspy';
import Particles from 'react-tsparticles';
import { FiX, FiMenu } from 'react-icons/fi';
import ServiceList from 'component/ServiceList';
import BlogContent from 'data/BlogContent';
import FooterTwo from 'component/FooterTwo';
import Contact from 'component/ContactTwo';
import Helmet from 'component/Helmet';

import logoLight from 'assets/images/logo/logo-light.png';
import logoAllDark from 'assets/images/logo/logo-all-dark.png';

const SlideList = [
  {
    textPosition: 'text-center',
    category: '',
    title: 'Hoppa Digital',
    description:
      'Hoppa Digital is a digital agency that specializes in creating custom websites, mobile applications, and digital marketing strategies.',
    buttonText: 'Contact Us',
    buttonLink: '#contact',
  },
];

class HomeParticles extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener('load', function () {
      console.log('All assets are loaded');
    });
  }

  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open');
  }
  CLoseMenuTrigger() {
    document.querySelector('.header-wrapper').classList.remove('menu-open');

    // var elementsMobileMenu = document.querySelectorAll('.mainmenunav ul.mainmenu li');
    // for(var i in elementsMobileMenu) {
    //     if(elementsMobileMenu.hasOwnProperty(i)) {
    //         elementsMobileMenu[i].onclick = function() {
    //             document.querySelector('.header-wrapper').classList.remove('menu-open');
    //         }
    //     }
    // }
  }

  stickyHeader() {}
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });

    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector('.submenu')
            .classList.toggle('active');
          this.classList.toggle('open');
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle='Hoppa Digital' />

        {/* Start Header Area  */}
        <header className='header-area formobile-menu header--fixed default-color'>
          <div className='header-wrapper' id='header-wrapper'>
            <div className='header-left'>
              <div className='logo'>
                <a href='/'>
                  <img className='logo-1' src={logoLight} alt='Logo Images' />
                  <img className='logo-2' src={logoAllDark} alt='Logo Images' />
                </a>
              </div>
            </div>
            <div className='header-right'>
              <nav className='mainmenunav d-lg-block'>
                <Scrollspy
                  className='mainmenu'
                  items={[
                    'home',
                    'service',
                    'about',
                    'portfolio',
                    'team',
                    'testimonial',
                    'blog',
                    'contact',
                  ]}
                  currentClassName='is-current'
                  offset={-200}
                >
                  <li onClick={this.CLoseMenuTrigger}>
                    <a href='#home'>Home</a>
                  </li>
                  <li onClick={this.CLoseMenuTrigger}>
                    <a href='#service'>Service</a>
                  </li>
                  <li onClick={this.CLoseMenuTrigger}>
                    <a href='#contact'>Contact</a>
                  </li>
                </Scrollspy>
              </nav>
              {/* Start Humberger Menu  */}
              <div className='humberger-menu d-block d-lg-none pl--20'>
                <span
                  onClick={this.menuTrigger}
                  className='menutrigger text-white'
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className='close-menu d-block d-lg-none'>
                <span onClick={this.CLoseMenuTrigger} className='closeTrigger'>
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}

        <div
          className='slider-activation slider-creative-agency with-particles'
          id='home'
        >
          <div className='frame-layout__particles'>
            <Particles
              className='particle'
              options={{
                style: {
                  position: 'absolute',
                },
                fpsLimit: 100,
                interactivity: {
                  detectsOn: 'canvas',
                  events: {
                    onClick: {
                      enable: true,
                      mode: 'push',
                    },
                    onHover: {
                      enable: true,
                      mode: 'repulse',
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 100,
                      duration: 2,
                      opacity: 0.8,
                      size: 10,
                      color: '#888',
                    },
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 100,
                      duration: 0.4,
                      color: '#888',
                    },
                  },
                },
                particles: {
                  color: {
                    value: '#888',
                  },
                  links: {
                    color: '#888',
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'bounce',
                    random: false,
                    speed: 6,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      value_area: 2000,
                    },
                    value: 80,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: 'circle',
                  },
                  size: {
                    random: true,
                    value: 5,
                  },
                },
                detectRetina: true,
              }}
            />
          </div>
          <div className='bg_image bg_image--27'>
            {SlideList.map((value, index) => (
              <div
                className='slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center'
                key={index}
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ''}
                        {value.title ? (
                          <h1 className='title theme-gradient'>
                            {value.title}
                          </h1>
                        ) : (
                          ''
                        )}
                        {value.description ? (
                          <p className='description'>{value.description}</p>
                        ) : (
                          ''
                        )}
                        {value.buttonText ? (
                          <div className='slide-btn'>
                            <a
                              className='rn-button-style--2 btn-primary-color'
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area  */}
        <div
          className='service-area creative-service-wrapper ptb--120 bg_color--1'
          id='service'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 col-12'>
                <div className='section-title text-left mb--30'>
                  <h2>Our Services</h2>
                </div>
              </div>
            </div>
            <div className='row creative-service'>
              <div className='col-lg-12'>
                <ServiceList
                  item='6'
                  column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Contact Us */}
        <div className='rn-contact-us ptb--120 bg_color--5' id='contact'>
          <Contact />
        </div>
        {/* End Contact Us */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
      </Fragment>
    );
  }
}
export default HomeParticles;
