import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import FooterTwo from 'component/FooterTwo';

const DynamicCountup = () => {
  return (
    <Fragment>
      <Header
        headertransparent='header--transparent'
        colorblack='color--black'
        logoname='logo.png'
      /> 
      <Outlet />
      <FooterTwo />
    </Fragment>
  );
};

export default DynamicCountup;
