import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'component/Header';
import FooterTwo from 'component/FooterTwo';

const Privacy = () => {
  return (
    <Fragment>
      <Header
        headertransparent='header--transparent'
        colorblack='color--black'
        logoname='logo.png'
      />
        <Outlet />
      <FooterTwo />
    </Fragment>
  );
};

export default Privacy;
