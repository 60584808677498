import React, { useState } from 'react';
import axios from 'utils/axiosInstance';

const Result = () => {
  return (
    <p className='success-message'>
      Your Message has been successfully sent. We will be in touch soon.
    </p>
  );
};
function ContactForm() {
  const [sent, setSent] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/email/contact', {
        email: e.target.email.value,
        subject: e.target.subject.value,
        message: e.target.message.value,
      });

      if (response.status === 200) setSent(true);
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form action='' onSubmit={sendEmail}>
      {/* <div className='rn-form-group'>
        <input type='text' name='fullname' placeholder='Your Name' required />
      </div> */}

      <div className='rn-form-group'>
        <input type='email' name='email' placeholder='Your Email' required />
      </div>

      {/* <div className='rn-form-group'>
        <input type='text' name='phone' placeholder='Phone Number' required />
      </div> */}

      <div className='rn-form-group'>
        <input type='text' name='subject' placeholder='Subject' required />
      </div>

      <div className='rn-form-group'>
        <textarea name='message' placeholder='Your Message' required></textarea>
      </div>

      <div className='rn-form-group'>
        <button
          className='rn-button-style--2 btn-solid'
          type='submit'
          value='submit'
          name='submit'
          id='mc-embedded-subscribe'
          disabled={sent}
        >
          Submit Now
        </button>
      </div>

      <div className='rn-form-group'>{sent ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
