import { useState, useCallback, useRef, useEffect } from 'react';
import Scrollspy from 'react-scrollspy';
import { FiX, FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import logoLight from 'assets/images/logo/logo-light.png';
import logoAllDark from 'assets/images/logo/logo-all-dark.png';
import logoDynamicCountup from 'assets/images/logo/logo-dynamic-countup-small.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const headerWrapperRef = useRef(null);
  const headerFixedRef = useRef(null);

  const menuTrigger = useCallback(() => {
    setMenuOpen((prev) => !prev);

    // document.querySelector('.header-wrapper').classList.toggle('menu-open');
  }, []);

  const closeMenuTrigger = useCallback(() => {
    setMenuOpen(false);

    // document.querySelector('.header-wrapper').classList.remove('menu-open');

    // var elementsMobileMenu = document.querySelectorAll('.mainmenunav ul.mainmenu li');
    // for(var i in elementsMobileMenu) {
    //     if(elementsMobileMenu.hasOwnProperty(i)) {
    //         elementsMobileMenu[i].onclick = function() {
    //             document.querySelector('.header-wrapper').classList.remove('menu-open');
    //         }
    //     }
    // }
  }, []);

  const stickyHeader = useCallback(() => {
    const handleScroll = () => {
      if (headerFixedRef.current) {
        if (window.scrollY > 100) {
          headerFixedRef.current.classList.add('sticky');
        } else {
          headerFixedRef.current.classList.remove('sticky');
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.addEventListener('load', function () {
      console.log('All assets are loaded');
    });

    stickyHeader();

    const elements = document.querySelectorAll('.has-droupdown > a');
    elements.forEach((element) => {
      element.addEventListener('click', function () {
        this.parentElement.querySelector('.submenu').classList.toggle('active');
        this.classList.toggle('open');
      });
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener('click', function () {
          this.parentElement
            .querySelector('.submenu')
            .classList.toggle('active');
          this.classList.toggle('open');
        });
      });
    };
  }, [stickyHeader]);

  useEffect(() => {
    if (headerWrapperRef.current) {
      if (menuOpen) {
        headerWrapperRef.current.classList.add('menu-open');
      } else {
        headerWrapperRef.current.classList.remove('menu-open');
      }
    }
  }, [menuOpen]);

  return (
    <header
      className='header-area formobile-menu header--fixed default-color'
      ref={headerFixedRef}
    >
      <div
        className='header-wrapper'
        id='header-wrapper'
        ref={headerWrapperRef}
      >
        <div className='header-left'>
          <div className='logo'>
            <a href='/'>
              <img className='logo-1' src={logoLight} alt='Logo Images' />
              <img className='logo-2' src={logoAllDark} alt='Logo Images' />
            </a>
          </div>
        </div>
        <div className='header-right'>
          <nav className='mainmenunav d-lg-block'>
            <Scrollspy
              className='mainmenu'
              items={[
                'home',
                'service',
                'about',
                'portfolio',
                'team',
                'testimonial',
                'blog',
                'contact',
              ]}
              currentClassName='is-current'
              offset={-200}
            >
              <li onClick={closeMenuTrigger}>
                <Link to='/dynamic-countup'>Home</Link>
              </li>
              <li onClick={closeMenuTrigger}>
                <Link to='how-to-use'>How to Use</Link>
              </li>
              <li onClick={closeMenuTrigger}>
                <Link to='terms'>Terms</Link>
              </li>
              <li onClick={closeMenuTrigger}>
                <Link to='privacy'>Privacy</Link>
              </li>
            </Scrollspy>
          </nav>
          <div className='header-btn'>
            <a href='#'>
              <img
                src={logoDynamicCountup}
                alt='Dynamic Countup'
                style={{ borderRadius: 5 }}
              />
            </a>
          </div>

          {/* Start Humberger Menu  */}
          <div className='humberger-menu d-block d-lg-none pl--20'>
            <span onClick={menuTrigger} className='menutrigger text-white'>
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className='close-menu d-block d-lg-none'>
            <span onClick={closeMenuTrigger} className='closeTrigger'>
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
