import Header from 'component/Header';
import FooterTwo from 'component/FooterTwo';

const Loader = () => {
  return (
    <>
      <Header
        headertransparent='header--transparent'
        colorblack='color--black'
        logoname='logo.png'
      />
      <div className='Loader-page-inner bg_color--4'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='inner'>
                <span>Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};
export default Loader;
