import React from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FaMediumM } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import logo from 'assets/images/logo/logo-light.png';

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: 'https://www.facebook.com/profile.php?id=61560371202358',
  },
  // { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  // { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaXTwitter />, link: 'https://x.com/hoppadigital' },
  { Social: <FaMediumM />, link: 'https://medium.com/@hoppa-digital' },
  { Social: <MdEmail />, link: 'mailto:team@hoppadigital.com' },
];

const FooterTwo = () => {
  return (
    <div
      className='footer-style-2 ptb--30 bg_image bg_image--27'
      data-black-overlay='6'
    >
      <div className='wrapper plr--50 plr_sm--20'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <div className='inner'>
              <div className='logo text-center text-sm-left mb_sm--20'>
                <a href='/'>
                  <img src={logo} alt='Logo images' />
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <div className='inner text-center'>
              <ul className='social-share rn-lg-size d-flex justify-content-center liststyle'>
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a
                      href={`${val.link}`}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
            <div className='inner text-lg-right text-center mt_md--20 mt_sm--20'>
              <div className='text'>
                <p>Copyright © 2024 Hoppa Digital. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
