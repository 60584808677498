import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorProvider } from 'context/ErrorContext';
import RegularPageLayout from 'layout/RegularPageLayout';
import DynamicCountupLayout from 'layout/DynamicCountupLayout';
import MainDemo from './MainDemo';
import Loader from 'page/Loader';
const DynamicCountup = lazy(() => import('page/DynamicCountup'));
const DynamicCountupHowto = lazy(() => import('page/DynamicCountup/Howto'));
const DynamicCountupTerms = lazy(() => import('page/DynamicCountup/Terms'));
const DynamicCountupPrivacy = lazy(() => import('page/DynamicCountup/Privacy'));
const Privacy = lazy(() => import('page/Privacy'));
const Error = lazy(() => import('page/Error404'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainDemo />,
  },
  {
    path: '/dynamic-countup',
    element: (
      <Suspense fallback={<Loader />}>
        <DynamicCountupLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <DynamicCountup />,
      },
      {
        path: 'terms',
        element: <DynamicCountupTerms />,
      },
      {
        path: 'privacy',
        element: <DynamicCountupPrivacy />,
      },
      {
        path: 'how-to-use',
        element: <DynamicCountupHowto />,
      },
      {
        path: '*',
        element: <Error />,
      },
    ],
  },
  {
    element: (
      <Suspense fallback={<Loader />}>
        <RegularPageLayout />
      </Suspense>
    ),
    children: [
      {
        path: '/privacy',
        element: <Privacy />,
      },
      {
        path: '*',
        element: <Error />,
      },
    ],
  },
]);

const App = () => {
  return (
    <ErrorProvider>
      <RouterProvider router={router} />
    </ErrorProvider>
  );
};

export default App;
