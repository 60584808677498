import React, { Component } from 'react';
import { FiCast, FiLayers, FiUsers, FiMonitor } from 'react-icons/fi';

const ServiceList = [
  {
    icon: <FiLayers />,
    title: 'Web App Development',
    description:
      'We design and develop dynamic websites that are morden, mobile responsive, and SEO optimized.',
  },
  {
    icon: <FiMonitor />,
    title: 'Mobile App Development',
    description:
      'We develop and deploy mobile applications that are user-friendly, effective, and engaging.',
  },
  {
    icon: <FiUsers />,
    title: 'Marketing & Reporting',
    description:
      'We provide marketing and reporting services to help you grow your business.',
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className='row'>
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <div className='service service__style--2'>
                <div className='icon'>{val.icon}</div>
                <div className='content'>
                  <h3 className='title'>{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
