import PageHelmet from 'component/Helmet';
import Header from './Header';
import FooterTwo from 'component/FooterTwo';
import { useError } from 'context/ErrorContext';

const ErrorFallback = () => {
  const { resetError } = useError();

  const reloadPage = () => {
    resetError();
    window.location.reload();
  };

  return (
    <>
      <Header
        headertransparent='header--transparent'
        colorblack='color--black'
        logoname='logo.png'
      />
      <PageHelmet pageTitle='Errors | Hoppa Digital' />
      {/* Start Page Error  */}
      <div className='error-page-inner bg_color--4'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='inner'>
                <h1 className='title theme-gradient'>520!</h1>
                <h3 className='sub-title'>Something went wrong</h3>
                <span>We have been notified and will be working on fixing this error.</span>
                <div className='error-button'>
                  <button
                    className='rn-button-style--2 btn-solid'
                    onClick={reloadPage}
                  >
                    Try Again
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Error  */}
      <FooterTwo />
    </>
  );
};
export default ErrorFallback;
